import React from "react";
import styles from "./Toggle.module.css";

const Toggle = ({ enable, setEnable, id, color }) => {
  return (
    <div className={styles.wrapper}>
      <input
        checked={enable}
        onChange={() => setEnable((prev) => !prev)}
        className={styles.switchCheckbox}
        id={id}
        type="checkbox"
      />
      <label
        style={{ background: enable ? "#FCD435" : "#fff" }}
        className={styles.switchLabel}
        htmlFor={id}
      >
        <span className={styles.switchButton} />
      </label>
    </div>
  );
};

export default Toggle;
