import React from "react";
import { FaArrowLeft } from "react-icons/fa";

import { useNavigate } from "react-router-dom";
import styles from "./BackButton.module.css";

const BackButton = ({ text }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.button} onClick={() => navigate(-1)}>
      <FaArrowLeft className={styles.icon} />
      <p className={styles.text}>{text}</p>
    </div>
  );
};

export default BackButton;
