import React from "react";
import styles from "./Actions.module.css";
import formatNumber from "../../utils";
const SingleAction = ({ title, value, inUsd, buttonText }) => {
  return (
    <div className={styles.singleAction}>
      <h4 className={styles.heading}>{title}</h4>

      <h3 className={styles.balance}>{formatNumber(value)} USD</h3>
      <p className={styles.inUsd}>${inUsd}</p>
      <button className={styles.button}>{buttonText}</button>
    </div>
  );
};

export default SingleAction;
