import React from "react";
import styles from "./styles.module.css";
import SinglePlan from "./SinglePlan";
import {
  cardBg1,
  cardBg2,
  cardBg3,
  cardBg4,
  cardBg5,
  cardBg6,
} from "../../../images/image";
import Cookies from "js-cookie";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

const AllPlan = ({ setTransferFunds, openModalWithPlanDetails }) => {
  let api = process.env.REACT_APP_API_URL;
  //  api = "http://localhost:8000"
  let user_id = Cookies.get("auth-token");
  const [plans, setPlans] = useState([]);
  console.log(plans)
  async function getPlans() {

    let { data } = await axios.get(api + "/user/plans", {
      headers: {
        "Content-Type": "application/json",
        "client-id": "Sentry " + process.env.REACT_APP_CLIENT_ID,
        Authorization: "Bearer " + user_id,
      },
    });


    let plansWithoutinsuranceinname = data.filter((el) => !el.name.includes("Insurance"));
    console.log(data);
    setPlans(plansWithoutinsuranceinname);
  }

  useEffect(() => {
    getPlans();
  }, []);


  let backgrounds = [cardBg1, cardBg2, cardBg3, cardBg4, cardBg5, cardBg6];

  return (

    <div className={styles.wrapper}>
      {plans?.map((el, i) => (
        <SinglePlan {...el} key={i} bg={backgrounds[i]} setTransferFunds={setTransferFunds} openModalWithPlanDetails={openModalWithPlanDetails} />
      ))}
    </div>
  );

};

export const InsurancePlans = ({ setTransferFunds, openModalWithPlanDetails }) => {
  let api = process.env.REACT_APP_API_URL;
  //  api = "http://localhost:8000"
  let user_id = Cookies.get("auth-token");
  const [plans, setPlans] = useState([]);

  async function getPlans() {

    let { data } = await axios.get(api + "/user/plans", {
      headers: {
        "Content-Type": "application/json",
        "client-id": "Sentry " + process.env.REACT_APP_CLIENT_ID,
        Authorization: "Bearer " + user_id,
      },
    });

    let plansWithinsuranceinname = data.filter((el) => el.name.includes("Insurance"));
    setPlans(plansWithinsuranceinname);


  }

  useEffect(() => {
    getPlans();
  }, []);


  let backgrounds = [cardBg1, cardBg2, cardBg3, cardBg4, cardBg5, cardBg6];

  return (

    <div className={styles.wrapper}>
      {plans?.map((el, i) => (
        <SinglePlan {...el} key={i} bg={backgrounds[i]} setTransferFunds={setTransferFunds} openModalWithPlanDetails={openModalWithPlanDetails} />
      ))}
    </div>
  );

};

export default AllPlan;
