import React from "react";
import styles from "./DataTable.module.css";

const SingleRow = ({
  assets,
  balance,
  price,
  change24h,
  value,
  allocation,
}) => {
  const formattedNumber = (
    number,
    showSign = true,
    currency = null,
    minimumFractionDigits = 2
  ) => {
    const sign = showSign ? (number >= 0 ? "+" : "") : "";

    let formattedAmount = number.toFixed(Math.max(minimumFractionDigits, 2));

    if (minimumFractionDigits > 0) {
      formattedAmount = parseFloat(formattedAmount).toFixed(
        minimumFractionDigits
      );
    }

    if (currency) {
      const currencyIcon = getCurrencyIcon(currency);
      return currencyIcon + sign + formattedAmount;
    } else {
      return sign + formattedAmount;
    }
  };

  const getCurrencyIcon = (currency) => {
    const currencyIcons = {
      USD: "$",
      EUR: "€",
      GBP: "£",
      // Add more currency codes and their respective icons as needed
    };

    return currencyIcons[currency] || "";
  };
  return (
    <>
      <tr className={[styles.row, styles.valueContainer].join(" ")}>
        <td className={styles.item}>
          <div className={styles.info}>
            <img src={assets.logo} alt="#" className={styles.logo} />
            <div className={styles.nameAndInfo}>
              <p className={[styles.name, styles.textAlignLeft].join(" ")}>
                {assets.name}
              </p>
              <p className={[styles.info, styles.textAlignLeft]}>
                {assets.info}
              </p>
            </div>
          </div>
        </td>
        <td className={styles.item}>{balance}</td>
        <td className={styles.item}>{formattedNumber(price, false, "USD")}</td>
        <td
          className={[
            styles.item,

            change24h > 0
              ? [styles.positiveAmount, styles.amount].join(" ")
              : [styles.negativeAmount, styles.amount].join(" "),
          ].join(" ")}
        >
          {formattedNumber(change24h)}%
        </td>
        <td className={styles.item}> {formattedNumber(value, false, "USD")}</td>
        <td className={styles.item}>
          {formattedNumber(allocation, false, false)}%
        </td>
      </tr>
    </>
  );
};

export default SingleRow;
