import React from "react";

import styles from "./Input.module.css";

const Input = ({ label, type, name, placeholder, onChange, value, text, disabled, is_currency }) => {
  // const handleKeyDown = (event) => {
  //   if (
  //     !(
  //       /[0-9]./.test(event.key) ||
  //       event.key === "Backspace" ||
  //       event.key === "ArrowUp" ||
  //       event.key === "ArrowDown"
  //     )
  //   ) {
  //     event.preventDefault();
  //   }
  // };

  return (
    <div className={styles.inputContainer}>
      <label htmlFor={name} className={`${styles.label}`}>
        {label}
      </label>
      <div className={styles.inputWrapper}>
        <input
          required
          type={type}
          id={name}
          name={name}
          disabled={disabled}
          value={value}

          className={`${styles.input} `}
          placeholder={placeholder}
          onChange={onChange}
        // onKeyDown={handleKeyDown}
        />
        {is_currency && <p className={styles.currency}>USD</p>}
      </div>
      <p className={styles.text}>{text}</p>
    </div>
  );
};

export default Input;
