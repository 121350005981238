import React, { useState } from "react";
import styles from "./DataTable.module.css";
import {
  weth,
  wbtc,
  aave,
  link,
  crv,
  inch,
  mana,
  comp,
  ocean,
  usdc,
} from "../../images/image";
import SingleRow from "./SingleRow";

const DataTable = () => {
  const [activeTab, setActiveTab] = useState(0);
  const tabs = ["Overview", "Portfolio"];
  const data = [
    [
      {
        assets: { logo: weth, name: "WETH", info: "Wrapped Ether" },
        balance: 100.096584718,
        price: 1573.18,
        change24h: 0.67,
        value: 157469.95,
        allocation: 24.91,
      },
      {
        assets: { logo: wbtc, name: "WBTC", info: "Wrapped BTC" },
        balance: 5.9848912,
        price: 22545.48,
        change24h: 1.12,
        value: 134932.24,
        allocation: 21.34,
      },
      {
        assets: { logo: aave, name: "AAVE", info: "Aave Token" },
        balance: 1039.03596051,
        price: 77.18,
        change24h: 1.94,
        value: 80189.31,
        allocation: 12.68,
      },
      {
        assets: { logo: link, name: "LINK", info: "ChainLink Token" },
        balance: 9921.79439251,
        price: 6.95,
        change24h: 0.7,
        value: 68972.07,
        allocation: 10.91,
      },
      {
        assets: { logo: crv, name: "CRV", info: "Curve DAO Token" },
        balance: 69898.0351947,
        price: 0.95,
        change24h: 2.69,
        value: 66700.75,
        allocation: 10.55,
      },
      {
        assets: { logo: inch, name: "1INCH", info: "1INCH Token" },
        balance: 75974.0105036,
        price: 0.53,
        change24h: 1.13,
        value: 40116.74,
        allocation: 6.35,
      },
      {
        assets: { logo: mana, name: "MANA", info: "Decentraland MANA" },
        balance: 52926.8487954,
        price: 0.61,
        change24h: 7.54,
        value: 32547.69,
        allocation: 5.15,
      },
      {
        assets: { logo: comp, name: "COMP", info: "Compound" },
        balance: 696.129963181,
        price: 46.13,
        change24h: 2.71,
        value: 32115.32,
        allocation: 5.08,
      },
      {
        assets: { logo: ocean, name: "OCEAN", info: "Ocean Token" },
        balance: 49686.6673688,
        price: 0.39,
        change24h: -0.22,
        value: 19597.61,
        allocation: 3.1,
      },
      {
        assets: { logo: usdc, name: "USDC", info: "USD Coin" },
        balance: 0.0,
        price: 1.0,
        change24h: -0.37,
        value: 0.0,
        allocation: 0.0,
      },
    ],
    [
      {
        assets: { logo: wbtc, name: "WBTC", info: "Wrapped BTC" },
        balance: 5.9848912,
        price: 22545.48,
        change24h: 1.12,
        value: 134932.24,
        allocation: 21.34,
      },
      {
        assets: { logo: aave, name: "AAVE", info: "Aave Token" },
        balance: 1039.03596051,
        price: 77.18,
        change24h: 1.94,
        value: 80189.31,
        allocation: 12.68,
      },
      {
        assets: { logo: link, name: "LINK", info: "ChainLink Token" },
        balance: 9921.79439251,
        price: 6.95,
        change24h: 0.7,
        value: 68972.07,
        allocation: 10.91,
      },
      {
        assets: { logo: weth, name: "WETH", info: "Wrapped Ether" },
        balance: 100.096584718,
        price: 1573.18,
        change24h: 0.67,
        value: 157469.95,
        allocation: 24.91,
      },
      {
        assets: { logo: wbtc, name: "WBTC", info: "Wrapped BTC" },
        balance: 5.9848912,
        price: 22545.48,
        change24h: 1.12,
        value: 134932.24,
        allocation: 21.34,
      },
      {
        assets: { logo: aave, name: "AAVE", info: "Aave Token" },
        balance: 1039.03596051,
        price: 77.18,
        change24h: 1.94,
        value: 80189.31,
        allocation: 12.68,
      },
      {
        assets: { logo: link, name: "LINK", info: "ChainLink Token" },
        balance: 9921.79439251,
        price: 6.95,
        change24h: 0.7,
        value: 68972.07,
        allocation: 10.91,
      },
      {
        assets: { logo: crv, name: "CRV", info: "Curve DAO Token" },
        balance: 69898.0351947,
        price: 0.95,
        change24h: 2.69,
        value: 66700.75,
        allocation: 10.55,
      },
      {
        assets: { logo: inch, name: "1INCH", info: "1INCH Token" },
        balance: 75974.0105036,
        price: 0.53,
        change24h: 1.13,
        value: 40116.74,
        allocation: 6.35,
      },
      {
        assets: { logo: mana, name: "MANA", info: "Decentraland MANA" },
        balance: 52926.8487954,
        price: 0.61,
        change24h: 7.54,
        value: 32547.69,
        allocation: 5.15,
      },
      {
        assets: { logo: comp, name: "COMP", info: "Compound" },
        balance: 696.129963181,
        price: 46.13,
        change24h: 2.71,
        value: 32115.32,
        allocation: 5.08,
      },
      {
        assets: { logo: ocean, name: "OCEAN", info: "Ocean Token" },
        balance: 49686.6673688,
        price: 0.39,
        change24h: -0.22,
        value: 19597.61,
        allocation: 3.1,
      },
      {
        assets: { logo: usdc, name: "USDC", info: "USD Coin" },
        balance: 0.0,
        price: 1.0,
        change24h: -0.37,
        value: 0.0,
        allocation: 0.0,
      },
    ],
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.textContainer}>
        <h4 className={styles.title}>LeaderBoard </h4>
        <p className={styles.text}>
          You have full control to manage your own account setting.
        </p>
      </div>
      <div className={styles.tabContainer}>
        {tabs.map((el, i) => (
          <p
            key={i}
            onClick={() => setActiveTab(i)}
            className={[styles.tab, activeTab === i && styles.activeTab].join(
              " "
            )}
          >
            {el}
          </p>
        ))}
      </div>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <tbody>
            <tr className={styles.row}>
              <th className={[styles.heading, styles.textAlignLeft].join(" ")}>
                Assets
              </th>
              <th className={styles.heading}>Balance</th>
              <th className={styles.heading}>Price</th>
              <th className={styles.heading}>Change 24h</th>
              <th className={styles.heading}>Value</th>
              <th className={styles.heading}>Allocation</th>
            </tr>
            {data[activeTab].map((el, index) => (
              <SingleRow {...el} key={index} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DataTable;
