import React from "react";
import styles from "./ActivePlan.module.css";
import { plan1, plan2, plan3 } from "../../../images/image";
import SinglePlan from "./SinglePlan/SinglePlan";
import axios from "axios";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useState } from "react";
const ActivePlan = () => {
  let api = process.env.REACT_APP_API_URL;
  let user_id = Cookies.get("auth-token");
  const [plans, setPlans] = useState([]);

  async function getActivePlan() {
    let { data } = await axios.get(api + "/user/get-investments", {
      headers: {
        "Content-Type": "application/json",
        "client-id": "Sentry " + process.env.REACT_APP_CLIENT_ID,
        Authorization: "Bearer " + user_id,
      },
    });
    console.log(data);

    let plans_array = [];

    for (let i = 0; i < data.data.length; i++) {
      let plan = data.data[i];
      let plan_obj = {
        image: plan1,
        id: plan.id,
        investedAmount: plan.amount / Math.pow(10, 8),
        startDate: new Date(plan.start_date).toLocaleString({
          timeZone: "UTC",
        }),
        endDate: new Date(plan.end_date).toLocaleString({
          timeZone: "UTC",
        }),
        totalReturn: plan.roi,
      };
      plans_array.push(plan_obj);
    }
    setPlans(plans_array);
  }

  useEffect(() => {
    getActivePlan();
  }
  , []);

  // const plans = [
  //   {
  //     image: plan1,
  //     id: "4u70138",
  //     investedAmount: 1000,
  //     startDate: "26 Feb, 2023 11:37 PM",
  //     endDate: "05 Mar, 2023 11:38 PM",
  //     totalReturn: 1157.5,
  //   },
  //   {
  //     image: plan2,
  //     id: "4u70138",
  //     investedAmount: 4000,
  //     startDate: "26 Feb, 2023 11:37 PM",
  //     endDate: "05 Mar, 2023 11:38 PM",
  //     totalReturn: 1157.5,
  //   },
  //   {
  //     image: plan3,
  //     id: "4u70138",
  //     investedAmount: 15000,
  //     startDate: "5 Jan, 2023 08:10 PM",
  //     endDate: "5 Feb, 2023 08:10 PM",
  //     totalReturn: 15980,
  //   },
  // ];
  return (
    <section className={styles.wrapper}>
      <h5 className={styles.title}>Active Plan ({plans.length})</h5>
      <div className={styles.allPlan}>
        {plans.map((el, i) => (
          <SinglePlan key={i} {...el} />
        ))}
      </div>
    </section>
  );
};

export default ActivePlan;
