import React, { useEffect, useState } from "react";
import styles from "./DataTable.module.css";

import SingleRow from "./SingleRow";
import { TxSingleRow } from "./SingleRow";

const DataTable = ({ investData }) => {
  const [activeTab, setActiveTab] = useState(0);
  const tabs = ["History", "Active", "Completed"];
  console.log(investData);
  const [data, setData] = useState([{
    id: "INV-82730006",
    plan: "Testpaket",
    date: "27 Feb, 2023 12:19 AM",
    investedAmount: 1000.0,
    receivedAmount: 0.0,
    status: "Active",
  },]);

  async function sortinvestments() {
    let data_holder = [];
    for (let i = 0; i < investData.length; i++) {
      let data = {
        id: investData[i].id,
        plan: investData[i].plan_id,
        date: investData[i].created_at,
        investedAmount: investData[i].amount / Math.pow(10, 8),
        receivedAmount: investData[i].roi,
        status: investData[i].status,
      }
      data_holder.push(data);
    }
    setData(data_holder);
  }
  useEffect(() => {
    sortinvestments();
  }, [investData]);
  // const data = [
  //   {
  //     id: "INV-82730006",
  //     plan: "Testpaket",
  //     date: "27 Feb, 2023 12:19 AM",
  //     investedAmount: 1000.0,
  //     receivedAmount: 0.0,
  //     status: "Active",
  //   },
  //   {
  //     id: "INV-76450005",
  //     plan: "Testpaket",
  //     date: "26 Feb, 2023 11:37 PM",
  //     investedAmount: 1000.0,
  //     receivedAmount: 0.0,
  //     status: "Active",
  //   },
  //   {
  //     id: "INV-67440004",
  //     plan: "Progressiv",
  //     date: "15 Feb, 2023 04:24 AM",
  //     investedAmount: 5000.0,
  //     receivedAmount: 0.0,
  //     status: "Cancelled",
  //   },
  //   {
  //     id: "INV-98320003",
  //     plan: "Exklusiv",
  //     date: "15 Feb, 2023 04:23 AM",
  //     investedAmount: 25000.0,
  //     receivedAmount: 0.0,
  //     status: "Cancelled",
  //   },
  //   {
  //     id: "INV-53100002",
  //     plan: "Spezialisiert",
  //     date: "15 Feb, 2023 04:23 AM",
  //     investedAmount: 50000.0,
  //     receivedAmount: 8541.67,
  //     status: "Cancelled",
  //   },
  //   {
  //     id: "INV-89900001",
  //     plan: "Konservativ",
  //     date: "15 Feb, 2023 04:23 AM",
  //     investedAmount: 5000,
  //     receivedAmount: 0.0,
  //     status: "Cancelled",
  //   },
  //   {
  //     id: "INV-53100002",
  //     plan: "Spezialisiert",
  //     date: "15 Feb, 2023 04:23 AM",
  //     investedAmount: 50000.0,
  //     receivedAmount: 8541.67,
  //     status: "Completed",
  //   },
  //   {
  //     id: "INV-89900001",
  //     plan: "Konservativ",
  //     date: "15 Feb, 2023 04:23 AM",
  //     investedAmount: 5000,
  //     receivedAmount: 0.0,
  //     status: "Pending",
  //   },
  // ];
  const filteredData = data?.filter((item) => {
    if (tabs[activeTab].toLowerCase() === "history") {
      return data;
    } else {
      return item.status.toLowerCase() === tabs[activeTab].toLowerCase();
    }
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.tabContainer}>
        {tabs.map((el, i) => (
          <div
            key={i}
            onClick={() => setActiveTab(i)}
            className={styles.tabTextWrapper}
          >
            <p
              className={[styles.tab, activeTab === i && styles.activeTab].join(
                " "
              )}
            >
              {" "}
              {el}
            </p>
            {el.toLowerCase() === tabs[activeTab].toLowerCase() &&
              tabs[activeTab].toLowerCase() !== "history" && (
                <span className={styles.totalItem}>{filteredData.length}</span>
              )}
          </div>
        ))}
      </div>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <tbody>
            <tr className={styles.row}>
              <th className={[styles.heading].join(" ")}>ID</th>
              <th className={styles.heading}>Plan</th>
              <th className={styles.heading}>Date</th>
              <th className={styles.heading}>Supported Amount</th>
              <th className={styles.heading}>Reward %</th>
              <th className={styles.heading}>Status</th>
            </tr>
            {filteredData.map((el, index) => (
              <SingleRow {...el} key={index} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};


export const TxTable = ({ txData }) => {
  const [activeTab, setActiveTab] = useState(0);
  const tabs = ["History", "Deposit", "Withdrawal"];
  console.log(txData);
  const [data, setData] = useState([{
    id: "INV-82730006",
    plan: "Testpaket",
    date: "27 Feb, 2023 12:19 AM",
    investedAmount: 1000.0,
    receivedAmount: 0.0,
    transaction_type: "Deposit",
    status: "Active",
  },]);

  async function sortinvestments() {
    let data_holder = [];
    for (let i = 0; i < txData.length; i++) {
      console.log(txData)
      let data = {
        id: txData[i].id,
        asset: txData[i].asset,
        date: txData[i].date,
        amount: txData[i].amount / Math.pow(10, 8),
        status: txData[i].status,
        transaction_type: txData[i].transaction_type,

      }
      data_holder.push(data);
    }
    setData(data_holder);
  }
  useEffect(() => {
    sortinvestments();
    console.log(txData);
  }, [txData]);

  const filteredData = data?.filter((item) => {
    console.log(item);
    if (tabs[activeTab].toLowerCase() === "history") {
      return data;
    } else {
      return item?.transaction_type.toLowerCase() === tabs[activeTab].toLowerCase();
    }
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.tabContainer}>
        {tabs.map((el, i) => (
          <div
            key={i}
            onClick={() => setActiveTab(i)}
            className={styles.tabTextWrapper}
          >
            <p
              className={[styles.tab, activeTab === i && styles.activeTab].join(
                " "
              )}
            >
              {" "}
              {el}
            </p>
            {el.toLowerCase() === tabs[activeTab].toLowerCase() &&
              tabs[activeTab].toLowerCase() !== "history" && (
                <span className={styles.totalItem}>{filteredData.length}</span>
              )}
          </div>
        ))}
      </div>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <tbody>
            <tr className={styles.row}>
              <th className={[styles.heading].join(" ")}>ID</th>
              <th className={styles.heading}>Asset</th>
              <th className={styles.heading}>Date</th>
              <th className={styles.heading}>Fiat Amount</th>
              <th className={styles.heading}>Crypto Amount</th>
              <th className={styles.heading}>Status</th>
            </tr>
            {filteredData.map((el, index) => (
              <TxSingleRow {...el} key={index} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DataTable;
