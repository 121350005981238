import React, { useState } from "react";
import styles from "./MyProile.module.css";
import Profile from "../../Component/MyProfile/Profile/Profile";
import Security from "../../Component/MyProfile/Security/Security";

const MyProfile = ({ userInfo }) => {
  const [activeTab, setActiveTab] = useState(0);
  const tabsDetailsArray = [
    {
      tabName: "Profile",
      heading: "Profile Info",
      tagline: "",
    },
    // {
    //   tabName: "Account",
    //   heading: "Account Info",
    //   tagline: " You have full control to manage your own account setting.",
    // },
    {
      tabName: "Security",
      heading: "Security Info",
      tagline: "",
    },
    // {
    //   tabName: "Activity",
    //   heading: "Activity Info",
    //   tagline: "You have full control to manage your own account tagline.",
    // },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.textContainer}>
        <h4 className={styles.heading}>
          {tabsDetailsArray[activeTab].heading}{" "}
        </h4>
        <p className={styles.text}>{tabsDetailsArray[activeTab].tagline}</p>
      </div>
      <div className={styles.tabContainer}>
        {tabsDetailsArray.map((el, i) => (
          <p
            key={i}
            onClick={() => setActiveTab(i)}
            className={[styles.tab, activeTab === i && styles.activeTab].join(
              " "
            )}
          >
            {el.tabName}
          </p>
        ))}
      </div>
      {activeTab === 0 && <Profile userInfo={userInfo} />}
      {activeTab === 1 && <Security userInfo={userInfo} />}
    </div>
  );
};

export default MyProfile;
