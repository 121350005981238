import React, { useEffect, useState } from "react";
import styles from "./MakeYourPayment.module.css";
import formatNumber from "../../Component/utils";
import { qrcode } from "../../images/image";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
const MakeYourPayment = () => {
  const [timeRemaining, setTimeRemaining] = useState(3600); //
  const [data, setData] = useState([]);
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining((prevTime) => prevTime - 1);
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };


  useEffect(() => {
    //fetch data 
    //set data to state
    let data = localStorage.getItem("deposit-data");
    if (data) {
      data = JSON.parse(data);
      setData(data);
    }

  }, []);
  return (
    <div className={styles.makeYourPayment}>
      <h2 className={styles.title}>Complete Deposit</h2>
      <p className={styles.text}>
        Please send only <span className={styles.hightlight}> {String(data?.currency).toUpperCase()}</span> to
        the address below.
      </p>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h3 className={styles.heading}>Deposit {String(data?.currency).toUpperCase()}</h3>
        </div>

        <div className={styles.mainBox}>
          {/* <img src={qrcode} alt="#" className={styles.qrCode} /> */}
          {/* <h3 className={styles.amount}>
            <span className={styles.highlight}>{formatNumber(10.0)} </span>{" "}
            <span className={styles.currency}>USDT</span>
          </h3> */}
          <h3 className={styles.balance}>
            Min Deposit : <span className={styles.highlight}>{"25 $"} </span>{" "}

          </h3>
          <h4 className={styles.addressText}>{String(data?.currency).toUpperCase()} Address</h4>
          <p
            //copy content 
            //on click copy
            onClick={() => {
              navigator.clipboard.writeText(
                localStorage.getItem("evm_wallet")
              );
              toast.success("Address copied");
            }}
            className={styles.address}>
            {localStorage.getItem("evm_wallet")}
          </p>
          <p className={styles.note}>Supported Networks: ERC-20, BEP-20, Arbitrum</p>
        </div>
        <div className={styles.bottomBox}>
          <p className={styles.warning}>
            Please make sure you send only {String(data?.currency).toUpperCase()} to this address.
          </p>
          <p className={styles.confirmation}>
            Account will credited once we received your payment.
          </p>
        </div>
      </div>{" "}
      <div className={styles.spaceBetween}>
        <button className={styles.cancelOrder}>Cancel Order</button>
        <Link to="/" className={styles.backToDashboard}>
          Back to Dashboard
        </Link>
      </div>
    </div>
  );
};

export default MakeYourPayment;
