import React from "react";
import DataTable from "../../Component/InvestmentHistory/DataTable/DataTable";
import { TxTable } from "../../Component/InvestmentHistory/DataTable/DataTable";
import BackButton from "../../Component/BackButton/BackButton";
import Heading from "../../Component/InvestmentHistory/Heading/Heading";
import styles from "./InvestmentHistoryPage.module.css";
import Cookies from "js-cookie";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";

const InvestmentHistoryPage = () => {
  const [investData, setInvestData] = useState({});
  async function getInvestments() {
    let api = process.env.REACT_APP_API_URL;
    let user_id = Cookies.get("auth-token");
    let { data } = await axios.get(api + "/user/get-investments/", {
      headers: {
        "Content-Type": "application/json",
        "client-id": "Sentry " + process.env.REACT_APP_CLIENT_ID,
        Authorization: "Bearer " + user_id,
      },
    });
    console.log(data);
    setInvestData(data.data);

  }

  useEffect(() => {
    getInvestments();
  }
    , []);
  return (
    <div className={styles.wrapper}>
      <div>
        <BackButton text="Home" />
        <Heading />
      </div>
      <DataTable investData={investData} />
    </div>
  );
};

export const DepositHistoryPage = () => {
  const [txData, setTxData] = useState({});
  async function getInvestments() {
    let api = process.env.REACT_APP_API_URL;
    let user_id = Cookies.get("auth-token");
    let { data } = await axios.get(api + "/user/get-transactions/", {
      headers: {
        "Content-Type": "application/json",
        "client-id": "Sentry " + process.env.REACT_APP_CLIENT_ID,
        Authorization: "Bearer " + user_id,
      },
    });
    console.log(data);
    setTxData(data.data);

  }

  useEffect(() => {
    getInvestments();
  }
    , []);
  return (
    <div className={styles.wrapper}>
      <div>
        <BackButton text="Home" />
        <Heading />
      </div>
      <TxTable txData={txData} />
    </div>
  );
};

export default InvestmentHistoryPage;
