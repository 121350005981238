import React from "react";
import styles from "./Heading.module.css";
import { loading } from "../../../images/image";

const Heading = ({ investData }) => {
  let inv = investData?.id;
  let id = String(inv)?.split("-")[0];
  return (
    <section className={styles.wrapper}>
      <h3 className={styles.heading}>ID : {id}</h3>
      <div className={styles.spaceBetween}>
        <div className={styles.statusContainer}>
          {/* <p className={styles.text}>INV-76450005</p> */}
          <p className={styles.status}>{investData?.status}</p>
        </div>
        <div className={styles.iconContainer}>
          <img
            onClick={() => window.location.reload()}
            src={loading} alt="#" className={styles.icon} />
        </div>
      </div>
    </section>
  );
};

export default Heading;
